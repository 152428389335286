/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Header from "./header";
import "./tailwind.css";
import "./style.css";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div className="flex flex-col min-h-screen">
      <Header siteTitle={data.site.siteMetadata.title} />
      <div className="p-8">
        <main>{children}</main>
      </div>
      <footer className="mt-auto bg-gray-700 text-white p-4 text-center">
        <a
          href="https://www.friendslibrary.com/?from_rhp=1"
          className="border-b border-dotted"
        >
          Early Quaker writings
        </a>
        <span className="px-4">|</span>
        <a
          href="https://www.bibliotecadelosamigos.org/?from_rhp=1"
          className="border-b border-dotted"
        >
          Los escritos de los primeros Cuaqueros
        </a>
      </footer>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
