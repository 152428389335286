import { Link } from "gatsby";
import React from "react";

const Header = () => (
  <header className="bg-gray-700 text-white p-4 text-center">
    <div>
      <h1>
        <Link to="/">Rachel Henderson Photography Blog [Archived]</Link>
      </h1>
    </div>
  </header>
);

export default Header;
